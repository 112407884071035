import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute } from "./auth.routes";

const Login = React.lazy(() => import("../screens/Login"));
const Recovery = React.lazy(() => import("../screens/Recovery"));
const NewPassword = React.lazy(() => import("../screens/NewPassword"));
const Register = React.lazy(() => import("../screens/Register"));
const Home = React.lazy(() => import("../screens/Home"));
const Course = React.lazy(() => import("../screens/Course"));
const Lesson = React.lazy(() => import("../screens/Lesson"));
const Categories = React.lazy(() => import("../screens/Categories"));

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/recovery" exact component={Recovery} />
          <Route path="/newpassword" exact component={NewPassword} />
          <Route
            path="/register/confirmtoken/:token"
            exact
            component={Register}
          />
          <AuthRoute path="/home" component={Home} />
          <AuthRoute
            path="/course/:course_uuid/lesson/:lesson_id"
            component={Lesson}
          />
          <AuthRoute path="/course/:uuid" component={Course} />
          <AuthRoute path="/categories/:id" component={Categories} />
          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
