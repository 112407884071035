import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  * {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    :hover{
      transition: 0.25s;
      opacity: 0.7;
    }
  }

  body {
    background-color: #FFFFFF;
    color: ${(props) => props.theme.colors.text};
    font-family: 'DM Sans';
  }

  h1 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    color: #FFFFFF;
  }

  h2 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
  }

  h3 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
  }

  h4 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
  }

  h5 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .p1{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .p2{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .p3{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
  }

  input {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  :root {
    --action-primary: #14FEFF;
    --action-secondary:#40bc77;
    --action-danger: #FF3A29;
    --action-success: #34B53A;
    --action-warning: #FFB200;


    --complementary-gray8: #151515;
    --complementary-gray7:#202125;
    --complementary-gray6: #101010;
    --complementary-gray5: #161616;
    --complementary-gray4: #414141;
    --complementary-gray3: #676767;
    --complementary-gray2: #888888;
    --complementary-gray1: #F8F8F8;

    --complementary-blue4: #249495;
    --complementary-blue3: #59FFFF;
    --complementary-blue2: #A8FEFF;
    --complementary-blue1: #DBFFFF;

    --complementary-orange4: #C04617;
    --complementary-orange3: #FF7642;
    --complementary-orange2: #FF966E;
    --complementary-orange1: #FFBEA6;

    --complementary-dangerlight: #FFB9B3;
    --complementary-successlight: #C4EEC6;

    --color-black: #000000;
    --color-white: #FFFFFF;

    --color-background: #000000;
  }



  /* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #101010;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--action-secondary);
  border-radius: 10px;
}

`
